import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Trans } from '@lingui/macro'
import routes from '~/routes'
import { Profile as Layout } from 'components/layout/Layout'
import { withLocale } from 'components/common/Locale'
import Button from 'components/common/Button'
import { Title, Actions, Main } from 'components/common/Form/Ui'

export default withLocale(({ i18n }) =>
  <Layout>
    <Title>
      <Trans>Validación pendiente</Trans>
    </Title>
    <Main>
      <Trans>
        Tu perfil de profesional aun esta siendo evaluado por nuestra organización.
      </Trans>
    </Main>
    <Actions>
      <Button component={ Link } isSecondaryDark to={ routes.home() }>
        <Trans>Ir al inicio</Trans>
      </Button>
    </Actions>
  </Layout>
)
