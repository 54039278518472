import React from 'react'
import { withLocale } from 'components/common/Locale'
import { Trans } from '@lingui/macro'
import HintedFieldWrapper from './HintedFieldWrapper'

export default withLocale(({ children }) =>
  <HintedFieldWrapper
    hint={ <Trans>Opcional</Trans> }
  >
    { children }
  </HintedFieldWrapper>
)
