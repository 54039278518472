import React from 'react'
import { Trans, t } from '@lingui/macro'
import { Link } from 'gatsby'
import Button from 'components/common/Button'
import routes from '~/routes'
import { withLocale } from 'components/common/Locale'
import style from './ui.module.scss'

const Submit = withLocale(({ i18n, actionLabel, isDisabled, children }) =>
  <div className={ style.actions }>
    <div className={ style.secondary }>
      { children }
    </div>
    <Button
      className={ style.button }
      component="button"
      isDisabled={ isDisabled }
      isPrimary
    >
      { actionLabel }
    </Button>
  </div>
)

export default Submit

export const SubmitOrLogin = withLocale(({ i18n, actionLabel, ...props }) => (
  <Submit {...props} actionLabel={ actionLabel || i18n._(t`Crea cuenta`) }>
    <Trans>¿Tienes cuenta?</Trans>
    <Link className={ style.secondaryActionLink } to={ routes.login() }>
      <Trans>Accede aquí.</Trans>
    </Link>
  </Submit>
))

export const SubmitOrRegister = withLocale(({ i18n, ...props }) => (
  <Submit {...props} actionLabel={ i18n._(t`Accede`) }>
    <Trans>¿No tienes cuenta?</Trans>
    <Link className={ style.secondaryActionLink } to={ routes.register() }>
      <Trans>Crea una aquí.</Trans>
    </Link>
  </Submit>
))
