import React from 'react'
import style from './ui.module.scss'

export default ({ component = 'h1', children }) => {
  const Component = component
  return (
    <Component className={ style.title }>
      { children }
    </Component>
  )
}
