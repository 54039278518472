import React from 'react'
import cx from 'classnames'
import style from './ui.module.scss'

export default ({ hint, children, isAfter = false }) =>
  <div className={ style.fieldWrapper }>
    { children }
    <span className={ cx(style.hint, {
      [style.hintAfter]: isAfter,
    }) }>
      { hint }
    </span>
  </div>